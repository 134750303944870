import React from "react";
import Littmann from "../assets/Littmann.jpg"

const redirectToExternalWebsite = () => {
  const externalURL = 'https://paystack.shop/mastermind-medical-supplies'; // Replace with the desired external URL
  window.location.href = externalURL;
};

const Analytics = () => {
  return (
    <div className="w-full bg-white py-16 px-4">
      <div className="max-w-[1240px] mx-auto grid md:grid-cols-2">
        <img className="w-[500px] mx-auto my-4" src={Littmann} alt="/" />
        <div className="flex flex-col justify-center">
          <p className="text-[#00df9a] font-bold">Mastermind eCommerce</p>
          <h1 className="md:text-4xl sm:text-3xl text-2xl font-bold py-2">Get everything you need in one place</h1>
          <p>
          Mastermind is your ultimate destination for top-quality medical student products. 
          We understand the unique needs of aspiring healthcare professionals and offer a comprehensive range of study aids, textbooks, lab coats, Littmann packages, quizzes and more. 
          Our products are meticulously curated to empower students on their educational journey. 
          Trust Mastermind for the tools you need to excel in your medical studies and achieve your dreams in the world of healthcare. 
          Elevate your learning experience with Mastermind today!
          </p>
          <button onClick={redirectToExternalWebsite}  className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto md:mx-0 py-3 text-black">Shop</button>
        </div>
      </div>
    </div>
  )
}

export default Analytics