import React from "react";
import Typed from 'react-typed';
import QuizPayCards from "./QuizPayCards";

const redirectToExternalWebsite = () => {
  const externalURL = 'https://docs.google.com/forms/d/e/1FAIpQLSeuExSJe9Bd6shNkBcq1jMqU_HTF6JpvEH93zOfAS6ac3Vmug/viewform'; // Replace with the desired external URL
  window.location.href = externalURL;
};

const TopicYr1MBChBBDSAnatomy = () => {
  return (
    <div className="text-white">
      <div className="max-w-[800px] mt-[40px] w-full mx-auto text-center flex flex-col justify-center">
        <p className="text-[#00df9a] font-bold p-2">MASTERMIND QUIZZES FOR MEDICS</p>
        <h1 className="md:text-5xl sm:text-4xl text-2xl font-bold md:py-6 mb-2">Study efficiently with Active Recall</h1>
        <div className="flex justify-center items-center">
          <p className="md:text-5xl sm:text-4xl text-xl font-bold py-4"></p>
          <Typed
          className="md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2 text-gray-400 mb-4" 
          strings={["Quizzes section"]} 
          typeSpeed={120} 
          backSpeed={140}
          />
        </div>
        <p className="md:text-2xl text-xl font-bold text-gray-500">You can scroll down and try a free trial or enroll to a paid plan to access all quizzes</p>
        <p className="md:text-2xl text-xl font-bold text-gray-500">The free trial contains the introduction topic only</p>
        <div className="py-5">
        <p className="mb-5 md:text-5xl text-3xl font-bold text-white">Pricing</p>
        </div>
        <div className="mt-500px text-black">
          <QuizPayCards />
        </div>
        <p className="md:text-2xl text-xl font-bold text-gray-500 py-3 mt-[20px]">Look at the free trial</p>
        <div className="mb-20 mt-2">
          <button onClick={redirectToExternalWebsite} className="bg-[#00df9a] py-2 text-black w-[200px] rounded-md font-medium mx-auto my-4">Free trial</button>
        </div>
      </div>
    </div>
  )
}

export default TopicYr1MBChBBDSAnatomy