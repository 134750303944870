import React from "react";
import Typed from 'react-typed';
import Year1ButtonInMBChBBDS from "./Year1ButtonInMBChBBDS";


const CourseMBChB = () => {
  return (
    <div className="text-white">
      <div className="max-w-[800px] mt-[50px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <p className="text-[#00df9a] font-bold p-2">MASTERMIND QUIZZES FOR MEDICS</p>
        <h1 className="md:text-5xl sm:text-4xl text-2xl font-bold md:py-6 mb-2">Study efficiently with Active Recall</h1>
        <div className="flex justify-center items-center">
          <p className="md:text-5xl sm:text-4xl text-xl font-bold py-4"></p>
          <Typed
          className="md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2 text-gray-400 mb-4" 
          strings={["Quizzes section"]} 
          typeSpeed={120} 
          backSpeed={140}
          />
        </div>
        <p className="md:text-2xl text-xl font-bold text-gray-500">Which year are you in</p>
        <div className="mb-2 mt-2">
          <Year1ButtonInMBChBBDS />
        </div>
        <div className="mb-2">
          <button className="bg-[#00df9a] py-2 text-black w-[200px] rounded-md font-medium mx-auto my-4">Year 2</button>
        </div>
        <div className="mb-2">
          <button className="bg-[#00df9a] py-2 text-black w-[200px] rounded-md font-medium mx-auto my-4">Year 3</button>
        </div>
        <div className="mb-2">
          <button className="bg-[#00df9a] py-2 text-black w-[200px] rounded-md font-medium mx-auto my-4">Year 4</button>
        </div>
        <div className="mb-2">
          <button className="bg-[#00df9a] py-2 text-black w-[200px] rounded-md font-medium mx-auto my-4">Year 5</button>
        </div>
        <div className="mb-2">
          <button className="bg-[#00df9a] py-2 text-black w-[200px] rounded-md font-medium mx-auto my-4">Year 6</button>
        </div>
      </div>
    </div>
  )
}

export default CourseMBChB