import React from "react";
import { useNavigate } from "react-router-dom";

const MBChBButtonInQuizzes = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("./MBChB");
  };
  return (
    <button className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black"onClick={handleClick}>MBChB</button>
  );
};

export default MBChBButtonInQuizzes
