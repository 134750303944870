import React from "react";
import { useNavigate } from "react-router-dom";

const ProductsButtonInHero = () => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate("./products");
  };
  return (
    <button className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black"onClick={handleClick}>Shop</button>
  );
};

export default ProductsButtonInHero
