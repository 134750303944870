import React from "react";
import labcoat from "../assets/lab-coat.png"
import stethoscope from "../assets/phonendoscope.png"
import book from "../assets/book.png"

const redirectToExternalWebsite = () => {
  const externalURL = 'https://paystack.shop/mastermind-medical-supplies'; // Replace with the desired external URL
  window.location.href = externalURL;
};

const Cards = () => {
  return (
    <div className="w-full py-[10rem] px-4 bg-white">
      <div className="max-w-[1240px] mx-auto md:grid grid-cols-3 gap-8">
        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
          <img className="w-20 mx-auto bg-white" src={labcoat} alt="/" />
          <h2 className="text-2xl font-bold text-center py-8">Lab Coats</h2>
          <p className="text-center text-4xl font-bold">Order Now</p>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">High Quality Silk</p>
            <p className="py-2 border-b mx-8">Custom Sizes</p>
          </div>
          <button onClick={redirectToExternalWebsite} className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3">Shop Now</button>
        </div>
        <div className="w-full shadow-xl bg-gray-100 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300">
          <img className="w-20 mx-auto bg-transparent" src={stethoscope} alt="/" />
          <h2 className="text-2xl font-bold text-center py-8">Littmann Classic</h2>
          <p className="text-center text-4xl font-bold">Order Now</p>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">High Quality</p>
            <p className="py-2 border-b mx-8">Affordable</p>
          </div>
          <button onClick={redirectToExternalWebsite} className="bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3">Shop Now</button>
        </div>
        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
          <img className="w-20 mx-auto bg-white" src={book} alt="/" />
          <h2 className="text-2xl font-bold text-center py-8">Books</h2>
          <p className="text-center text-4xl font-bold">Order Now</p>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">Perfectly Bound</p>
            <p className="py-2 border-b mx-8">Inspected</p>
          </div>
          <button onClick={redirectToExternalWebsite} className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3">Shop Now</button>
        </div>
      </div>
    </div>
  )
}

export default Cards