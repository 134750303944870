import React from "react";
import Typed from 'react-typed';
import MBChBButtonInQuizzes from "./MBChBButtonInQuizzes";
import BDSButtonInQuizzes from "./BDSButtonInQuizzes";


const Quizzes = () => {
  return (
    <div className="text-white">
      <div className="max-w-[800px] mt-[-96px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <p className="text-[#00df9a] font-bold p-2">MASTERMIND QUIZZES FOR MEDICS</p>
        <h1 className="md:text-5xl sm:text-4xl text-2xl font-bold md:py-6 mb-2">Study efficiently with Active Recall</h1>
        <div className="flex justify-center items-center">
          <p className="md:text-5xl sm:text-4xl text-xl font-bold py-4"></p>
          <Typed
          className="md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2 text-gray-400 mb-4" 
          strings={["Quizzes section"]} 
          typeSpeed={120} 
          backSpeed={140}
          />
        </div>
        <p className="md:text-2xl text-xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-yellow-500 via-red-500 to-pink-500">Human Anatomy Quizzes are now available for first year students!</p>
        <p className="md:text-2xl text-xl font-bold text-transparent bg-clip-text bg-gradient-to-br from-yellow-500 via-red-500 to-pink-500">Don't miss out!</p>
        <p className="md:text-2xl text-xl font-bold text-gray-500">Which course are you studying</p>
        <div className="mb-2 mt-2">
          <MBChBButtonInQuizzes />
        </div>
        <div className="mb-2">
          <BDSButtonInQuizzes />
        </div>
      </div>
    </div>
  )
}

export default Quizzes