import React from 'react';
import Layout from './components/Layout';
/*
import Navbar from './components/Navbar';
import Hero from './components/Hero';
import Analytics from './components/Analytics';
import Newsletter from './components/Newsletter';
import Cards from './components/Cards';
import Footer from './components/Footer';
*/
import { Route, Routes} from 'react-router-dom'
import Products from './components/Products';
import Navbar from './components/Navbar';
import Quizzes from './components/Quizzes';
import CourseMBChB from './components/CourseMBChB';
import CourseBDS from './components/CourseBDS';
import UnitYr1MBChBBDS from './components/UnitYr1MBChBBDS';
import TopicYr1MBChBBDSAnatomy from './components/TopicYr1MBChBBDSAnatomy';

function App() {
  return (
    <div className="App">
      <Navbar />
        <Routes>
          <Route path="/" element={<Layout />} />
          <Route path="/products" element={<Products />} />
          <Route path="/products/quizzes" element={<Quizzes />} />
          <Route path="/products/quizzes/MBChB" element={<CourseMBChB />} />
          <Route path="/products/quizzes/BDS" element={<CourseBDS />} />
          <Route path="/products/quizzes/MBChB/Year1units" element={<UnitYr1MBChBBDS />} />
          <Route path="/products/quizzes/MBChB/Year1units/Year1anatsubunits" element={<TopicYr1MBChBBDSAnatomy />} />
          <Route path="/products/quizzes/BDS/Year1units" element={<UnitYr1MBChBBDS />} />
          <Route path="/products/quizzes/BDS/Year1units/Year1anatsubunits" element={<TopicYr1MBChBBDSAnatomy />} />
          <Route path="*" element={<h1>YOU ARE NOT IN A VALID ROUTE</h1>} />
        </Routes>
    </div>
  );
}

export default App;
