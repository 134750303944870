import React from "react";
import {
  FaInstagram
} from "react-icons/fa"

const handleInstagramClick = () => {
  const username = 'mastermindafrica';
  window.open(`https://www.instagram.com/${username}/`, '_blank');
};

const Footer = () => {
  return (
    <div className="max-w-[1240px] mx-auto py-16 px-4 grid lg:-cols-3 gap-8 text-gray-300">
      <div>
        <h1 className="w-full text-3xl font-bold text-[#00df9a]">MASTERMIND.</h1>
        <p className="py-4">
        Elevate your medical education with Mastermind, the premier provider of resources for medical students. 
        Our extensive catalog features cutting-edge medical textbooks, state-of-the-art lab equipment, and interactive learning materials. 
        Whether you're a seasoned student or just starting your journey, Mastermind equips you with the tools to excel in your studies and embrace a rewarding career in medicine. 
        Choose excellence, choose Mastermind.
        </p>
        <div className="flex justify-between md:w-[100%] my-6">
          <div onClick={handleInstagramClick} style={{ cursor: 'pointer' }}>
            <FaInstagram size={30} />
          </div>
        </div>
      </div>
      <div className="lg:col-span-2 flex justify-between mt-6">
        <div>
          <h6 className="font-medium text-gray-400">Solutions</h6>
          <ul>
            <li className="py-2 text-sm">Analytics</li>
            <li className="py-2 text-sm">Marketing</li>
            <li className="py-2 text-sm">Commerce</li>
            <li className="py-2 text-sm">Insights</li>
          </ul>
        </div>
        <div>
          <h6 className="font-medium text-gray-400">Company</h6>
          <ul>
            <li className="py-2 text-sm">About</li>
            <li className="py-2 text-sm">Blog</li>
            <li className="py-2 text-sm">Jobs</li>
          </ul>
        </div>
        <div>
          <h6 className="font-medium text-gray-400">Support</h6>
          <ul>
            <li className="py-2 text-sm">Pricing</li>
            <li className="py-2 text-sm">Documentation</li>
            <li className="py-2 text-sm">Guides</li>
            <li className="py-2 text-sm">API Status</li>
          </ul>
        </div>
        <div>
          <h6 className="font-medium text-gray-400">Legal</h6>
          <ul>
            <li className="py-2 text-sm">Copyright</li>
            <li className="py-2 text-sm">Policy</li>
            <li className="py-2 text-sm">Terms</li>
          </ul>
        </div>
      </div>
      <p className="mx-auto">&copy; {new Date().getFullYear()} Mastermind Africa. All rights reserved.</p>
    </div>
  )
}

export default Footer