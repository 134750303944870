import React from 'react';

const RequestButtonInHero = () => {
  const emailAddress = 'mastermindafrica3@gmail.com';

  const handleButtonClick = () => {
    window.location.href = `mailto:${emailAddress}`;
  };

  return (
    <button className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto py-3 text-black"onClick={handleButtonClick}>Request a Product/Service</button>
  );
};

export default RequestButtonInHero;
