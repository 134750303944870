import React from "react";
import Typed from 'react-typed';
import ProductsButtonInHero from "./ProductsButtonInHero";
import QuizzesButtonInHero from "./QuizzesButtonInHero";
import RequestButtonInHero from "./RequestButtonInHero";

const Hero = () => {
  return (
    <div className="text-white">
      <div className="max-w-[800px] mt-[-30px] w-full h-screen mx-auto text-center flex flex-col justify-center">
        <p className="text-[#00df9a] font-bold p-2">MASTERMIND MEDICAL SUPPLIES</p>
        <h1 className="md:text-7xl sm:text-6xl text-4xl font-bold md:py-6">Get everything you need</h1>
        <div className="flex justify-center items-center">
          <p className="md:text-5xl sm:text-4xl text-xl font-bold py-4">We supply:</p>
          <Typed
          className="md:text-5xl sm:text-4xl text-xl font-bold md:pl-4 pl-2 text-gray-400" 
          strings={["Books", "Notes", "Lab Coats", "Littmann Classic", "Revision Quizzes", "All Med Essentials"]} 
          typeSpeed={120} 
          backSpeed={140}
          loop
          />
        </div>
        <p className="md:text-2xl text-xl font-bold text-gray-500">Check out our offers to get the highest quality medical essentials</p>
        <ProductsButtonInHero />
        <QuizzesButtonInHero />
        <RequestButtonInHero />
      </div>
    </div>
  )
}

export default Hero