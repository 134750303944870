import React from "react";

const redirectToMonthlyPlan = () => {
  const externalURL = 'https://paystack.com/pay/34d-pgh3t5'; // Replace with the desired external URL
  window.location.href = externalURL;
};

const redirectToSemesterPlan = () => {
  const externalURL = 'https://paystack.com/pay/ymvs3glnwv'; // Replace with the desired external URL
  window.location.href = externalURL;
};

const redirectToYearlyPlan = () => {
  const externalURL = 'https://paystack.com/pay/2hd3frsvu0'; // Replace with the desired external URL
  window.location.href = externalURL;
};

const redirectToMonthlyPlanMpesa = () => {
  const externalURL = 'https://paystack.com/pay/mtbu3uamr1'; // Replace with the desired external URL
  window.location.href = externalURL;
};

const redirectToSemesterPlanMpesa = () => {
  const externalURL = 'https://paystack.com/pay/fj7mwzd33e'; // Replace with the desired external URL
  window.location.href = externalURL;
};

const redirectToYearlyPlanMpesa = () => {
  const externalURL = 'https://paystack.com/pay/u2kx2zpk8x'; // Replace with the desired external URL
  window.location.href = externalURL;
};

const QuizPayCards = () => {
  return (
    <div className="w-full py-[10rem] px-4 bg-white rounded-lg">
      <div className="max-w-[1240px] mx-auto md:grid grid-cols-3 gap-8">
        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">

          <h2 className="text-2xl font-bold text-center py-8">Monthly Plan</h2>
          <p className="text-center text-4xl font-bold">Enroll Now</p>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">Pay a monthly fee of Ksh 200</p>
            <p className="py-2 border-b mx-8">Ksh 200/month</p>
            <p className="py-2 border-b mx-8">Save 0%</p>
            <p className="py-2 border-b mx-8">Card Payment is recommended for Autorenewal</p>
          </div>
          <button onClick={redirectToMonthlyPlan} className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3">Enroll using Card</button>
          <button onClick={redirectToMonthlyPlanMpesa} className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3">Enroll using Mpesa</button>
        </div>
        <div className="w-full shadow-xl bg-gray-100 flex flex-col p-4 md:my-0 my-8 rounded-lg hover:scale-105 duration-300">
          
          <h2 className="text-2xl font-bold text-center py-8">Per Semester Plan</h2>
          <p className="text-center text-4xl font-bold">Enroll Now</p>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">Pay a semester fee of Ksh 360</p>
            <p className="py-2 border-b mx-8">Ksh 90/month</p>
            <p className="py-2 border-b mx-8">Save 55%</p>
            <p className="py-2 border-b mx-8">Card Payment is recommended for Autorenewal</p>
          </div>
          <button onClick={redirectToSemesterPlan} className="bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3">Enroll using Card</button>
          <button onClick={redirectToSemesterPlanMpesa} className="bg-black text-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3">Enroll using Mpesa</button>
        </div>
        <div className="w-full shadow-xl flex flex-col p-4 my-4 rounded-lg hover:scale-105 duration-300">
          
          <h2 className="text-2xl font-bold text-center py-8">Yearly Plan</h2>
          <p className="text-center text-4xl font-bold">Enroll Now</p>
          <div className="text-center font-medium">
            <p className="py-2 border-b mx-8 mt-8">Pay a yearly fee of Ksh 960</p>
            <p className="py-2 border-b mx-8">Ksh 80/month</p>
            <p className="py-2 border-b mx-8">Save 60%</p>
            <p className="py-2 border-b mx-8">Card Payment is recommended for Autorenewal</p>
          </div>
          <button onClick={redirectToYearlyPlan} className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3">Enroll using Card</button>
          <button onClick={redirectToYearlyPlanMpesa} className="bg-[#00df9a] w-[200px] rounded-md font-medium my-6 mx-auto px-6 py-3">Enroll using Mpesa</button>
        </div>
      </div>
    </div>
  )
}

export default QuizPayCards