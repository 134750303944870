import React from "react";
import QuizzesButtoninProducts from "./QuizzesButtonInProducts";

const redirectToAllProducts = () => {
  const externalURL = 'https://paystack.shop/mastermind-medical-supplies'; // Replace with the desired external URL
  window.location.href = externalURL;
};

const redirectToAllLectureNotes = () => {
  const externalURL = 'https://paystack.shop/mastermind-lecture-notes'; // Replace with the desired external URL
  window.location.href = externalURL;
};

const redirectToBookStore = () => {
  const externalURL = 'https://paystack.shop/mastermind-bookstore'; // Replace with the desired external URL
  window.location.href = externalURL;
};

const redirectToLabEssentials = () => {
  const externalURL = 'https://paystack.shop/mastermind-lab-essentials'; // Replace with the desired external URL
  window.location.href = externalURL;
};

const redirectToClinicalEssentials = () => {
  const externalURL = 'https://paystack.shop/mastermind-clinical-essentials'; // Replace with the desired external URL
  window.location.href = externalURL;
};

const Products = () => {
  return (
    <div className="text-white">
      <div className="mx-auto text-center py-2 justify-center w-full">
        <p className="py-2 font-bold md:text-5xl sm:text-4xl text-xl">Which product do you want to shop</p>
        <div className="mb-2">
          <button onClick={redirectToAllProducts} className="bg-[#00df9a] py-2 text-black w-[200px] rounded-md font-medium mx-auto my-4">Check All Products</button> 
        </div>
        <div className="mb-2">
          <button onClick={redirectToAllLectureNotes} className="bg-[#00df9a] py-2 text-black w-[200px] rounded-md font-medium mx-auto my-4">Lecture Notes</button> 
        </div>
        <div className="mb-2">
          <button onClick={redirectToBookStore} className="bg-[#00df9a] py-2 text-black w-[200px] rounded-md font-medium mx-auto my-4">Textbooks</button>
        </div>
        <div className="mb-2">
          <button onClick={redirectToLabEssentials} className="bg-[#00df9a] py-2 text-black w-[200px] rounded-md font-medium mx-auto my-4">Lab Gear</button>
        </div>
        <div className="mb-2">
          <button onClick={redirectToClinicalEssentials} className="bg-[#00df9a] py-2 text-black w-[200px] rounded-md font-medium mx-auto my-4">Clinicals Gear</button>
        </div>
        <div className="mb-2">
          <QuizzesButtoninProducts />
        </div>
      </div>
    </div>
  )
}

export default Products